import {BrowserRouter as Router} from "react-router-dom";
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import Index from "./view/Index";
import {RecoilRoot} from "recoil";

dayjs.locale('zh-cn');

function App() {
  return <Router>
    <RecoilRoot>
      <Index/>
    </RecoilRoot>
  </Router>;
}

export default App;