const app = JSON.parse(localStorage.getItem("app")) || {
  osType: navigator.userAgent.match(/iphone os|ipad|mac os/i)
    ? "ios"
    : "android",
  osInfo: "",
  imei: "",
  udid: "",
  sdkVersion: "3.0.0",
  agent: "x7z1",
  brand: "9917",
  appId: undefined,
  gameId: undefined,
};
app.altUsername = undefined;
const general = {
  native: true,
  app: app,
  setApp() {
    let app = { ...this.app };
    app.altUsername = undefined;
    localStorage.setItem("app", JSON.stringify(app));
  },
  user: {},
  history: null,
  urls: [],
};

general.app.osType = navigator.userAgent.match(/iphone os|ipad|mac os/i)
  ? "ios"
  : "android";

general.goBack = () => {
  if (general.urls.length > 1) {
    general.urls.splice(-1, 1);
    let url = general.urls[general.urls.length - 1];
    general.history.replace(url.pathname, "back");
  } else {
    general.history.replace("/");
  }
};

general.openUrl = (url) => {
  if (window.location === window.top.location) {
    console.log("openUrl", url);
    window.location.href = url;
  } else {
    console.log("openUrl postMessage", url);
    window.top.postMessage(
      JSON.stringify({
        type: "history",
        url: url,
      }),
      "*"
    );
  }
};

export default general;
