import { useLockFn, useMount } from "ahooks";
import Dialog from "../general/Dialog";
import axios from "../../axios";
import { useState } from "react";
import Empty from "../general/Empty";
import { Link } from "react-router-dom";
import general from "../../general";
import useActiveState from "../general/useActiveState";
import useUrlState from "@ahooksjs/use-url-state";
import { useRecoilState } from "recoil";
import badgeState from "../general/badgeState";

export default () => {
  const [params] = useUrlState();
  const [badge, setBadge] = useRecoilState(badgeState);
  const [app] = useActiveState("app");
  const [loading, setLoading] = useState();
  const [datas, setDatas] = useState([]);
  const load = useLockFn(async () => {
    Dialog.loading();
    setLoading(true);
    await axios
      .get("/msg/getMsgList")
      .finally(() => {
        Dialog.close();
        setLoading(false);
      })
      .then((res) => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        setDatas(res.data.data);
      })
      .catch((err) => {
        Dialog.error(err.message);
      });
  });
  useMount(load);

  return (
    <div className="user-msgs">
      {datas.length > 0 ? (
        <div className="items">
          {datas.map((item) => (
            <Link
              key={item.id}
              replace
              className={`item ${item.readOrNot ? "" : "read"}`}
              to={`/user/msg?id=${item.id}`}
              onClick={() => {
                general.msg = item;
                setBadge((badge) => {
                  if (badge.msg > 0 && item.readOrNot) {
                    badge = { ...badge };
                    badge.msg--;
                  }
                  return badge;
                });
              }}
            >
              <div className="head">
                <div className="title">
                  <div className="titled">{item.title}</div>
                  {item.readOrNot ? <div className="badge" /> : null}
                </div>
                <div className="time">{item.create_time.slice(0, 10)}</div>
              </div>
              <div className="body">{item.content}</div>
            </Link>
          ))}
        </div>
      ) : null}
      {datas.length == 0 && !loading ? <Empty /> : null}
    </div>
  );
};
