import { useContext } from "react";
import { ServiceContext } from "../general/ServiceContext";

export default () => {
  const serviceData = useContext(ServiceContext);

  function serviceContact() {
    console.log("serviceContact");

    let url = serviceData.wxUrl;
    if (window.location === window.top.location) {
      console.log("微信跳转222------------");
      location.href = url;
    } else {
      console.log("微信跳转333------------");
      window.parent.postMessage(
        JSON.stringify({
          type: "history",
          url: url,
        }),
        "*"
      );
    }
  }

  return (
    <div className="service-index">
      <div className="service-container">
        {serviceData.img_url ? (
          <div className="service-img">
            <img src={serviceData.img_url} alt="" />
            <p>请您自行保存图片，扫描添加客服后进行咨询</p>
          </div>
        ) : (
          <div onClick={serviceContact} className="btn">
            联系微信客服
          </div>
        )}
      </div>
      {/* <div className="service-version">版本：{app.sdkVersion.toString()}</div> */}
    </div>
  );
};
