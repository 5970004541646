import {useState} from "react";
import {useEventListener, useInterval, useLocalStorageState, useThrottleFn} from "ahooks";
import Dialog from "./Dialog";
import URI from "urijs";
import general from "../../general";
import useActiveState from "./useActiveState";

export default ({scheme = 'box9917://', url = 'https://app.9917.cn?a=test', className = null, children = null, success = null, error = null}) => {
  const [app] = useActiveState('app');
  if (!success) {
    success = () => {
      console.log('success');
    }
  }
  if (!error) {
    error = () => {
      if (app.origin === 'box9917') {
        return;
      }
      Dialog.alert('提示', '还未安装游戏盒子 去下载', () => {
        window.location.href = `sdk://browser?url=${encodeURIComponent(url)}`;
      });
    }
  }
  const {run: clear} = useThrottleFn(
    () => {
      if (interval) {
        setInterval(null);
        success();
      }
    }
  );
  const {run: func} = useThrottleFn(
    () => {
      if (window.document.hidden || window.document.webkitHidden) {
        clear();
      }
    },
  );
  useEventListener('visibilitychange', func, {target: window.document});
  useEventListener('webkitvisibilitychange', func, {target: window.document});
  useEventListener('pagehide', clear);

  const [interval, setInterval] = useState(null);
  useInterval(() => {
      error();
      setInterval(null);
    },
    interval
  );
  const click = () => {
    general.openUrl(scheme);
    setInterval(first ? 1024 : 1920);
    setFirst(true);
  }

  const [first, setFirst] = useLocalStorageState('scheme-' + URI(scheme).scheme());

  return <div className={className} onClick={click}>
    {children}
  </div>;
};
