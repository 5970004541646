import {useHistory} from "react-router-dom";
import {useBoolean, useLocalStorageState, useMount, useReactive, useSetState, useSize, useUpdate, useUpdateEffect} from "ahooks";
import axios from "../../axios";
import useActiveState from "../general/useActiveState";
import {useEffect, useRef, useState} from "react";
import URI from "urijs";

const {mTouch} = window;

export default ({container}) => {
  const history = useHistory();
  const [app] = useActiveState('app');
  const [data, setData] = useState();

  useMount(() => {
    axios.get('/msg/getSuspension')
      .then(({data: res}) => {
        let resData = res?.data;
        if (resData?.msg) {
          setData({
            text: resData?.msg,
            url: URI(resData?.url).resource(),
          });
        }
      });
  });

  const [init, setInit] = useState();
  const ref = useRef();
  const [move, {setTrue: startMove, setFalse: stopMove}] = useBoolean();
  const size = useSize(ref);
  const [position, setPosition] = useLocalStorageState('game-assist', {top: 10, left: 10});
  const [direction, setDirection] = useState();
  useEffect(() => {
    const el = ref.current;
    if (el && size.width && size.height && !position) {
      setPosition({top: el.offsetTop, left: el.offsetLeft});
    }
  }, [size.width, size.height, position]);

  useUpdateEffect(() => {
    if (init) {
      return;
    }
    setInit(true);
    mTouch('.game-assist .icon')
      .on('tap', () => {
        history.replace('/user');
      })
      .on('swipestart', () => {
        startMove();
        setPosition(position => ({
          ...position,
          _top: position.top,
          _left: position.left
        }));
      })
      .on('swiping', e => {
        e.stopPropagation();
        setPosition(position => ({
          ...position,
          top: position._top + e.mTouchEvent.moveY,
          left: position._left + e.mTouchEvent.moveX,
        }));
      })
      .on('swipeend', stopMove);
  });
  useEffect(() => {
    if (!move && container.current && size.width && size.height) {
      let width = container.current.clientWidth;
      let height = container.current.clientHeight;
      let {top, left} = position;
      if (top < 0) {
        top = 0;
      }
      if (top > height - size.height) {
        top = height - size.height;
      }
      if (left > 0 || left < -(size.width / 5)) {
        if (left > width / 2) {
          left = width - (size.width / 5) * 4;
        } else {
          left = -(size.width / 5);
        }
      }
      if (left > width / 2) {
        setDirection('right');
      } else {
        setDirection('left');
      }
      if (top != position.top || left != position.left) {
        setPosition({top, left});
      }
    }
  }, [position, move, size]);

  return <div className="game-assist" style={{...position, transition: `${move ? 'none' : 'all .25s'}`}}>
    <div className="icon" style={{backgroundImage: `url(${app.appinfo?.icon})`}} ref={ref}/>
    {data ? <div className={`tips tips-${direction}`} onClick={() => {
      history.replace(data.url);
      setData(null);
    }}>{data.text}</div> : null}
  </div>;
}
