import Dialog from "../general/Dialog";
import { useState } from "react";
import { useLockFn, useMount, useUpdate } from "ahooks";
import axios from "../../axios";
import { Link } from "react-router-dom";
import general from "../../general";
import Empty from "../general/Empty";
import badgeState from "../general/badgeState";
import { useRecoilState } from "recoil";
import Receive from "./Receive";

export default () => {
  const [badge, setBadge] = useRecoilState(badgeState);
  const update = useUpdate();
  const [loading, setLoading] = useState();
  const [datas, setDatas] = useState([]);
  const [current, setCurrent] = useState();
  const load = useLockFn(async () => {
    Dialog.loading();
    setLoading(true);
    await axios
      .get("/gift/gamePackList")
      .finally(() => {
        Dialog.close();
        setLoading(false);
      })
      .then((res) => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        let datas = res.data.data.gameList;
        setDatas(datas);
        datas.length > 0 && setCurrent(datas[0]);
        setBadge((badge) => ({ ...badge, pack: 0 }));
      })
      .catch((err) => {
        Dialog.error(err.message);
      });
  });
  useMount(load);

  const receive = useLockFn(async (gift) => {
    if (gift.is_receive) {
      return;
    }
    Dialog.loading();
    gift.receiving = true;
    update();
    await axios
      .post("/gift/packReceive", { pack_id: gift.packid })
      .finally(() => {
        Dialog.close();
        gift.receiving = false;
        update();
      })
      .then((res) => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        Dialog.success("领取成功");
        gift.is_receive = true;
        update();
      })
      .catch((err) => {
        Dialog.error(err.message);
      });
  });

  const [receiveData, setReceiveData] = useState();

  return (
    <>
      <div
        className={`gift-gifts ${
          !current?.list?.length && !loading ? "empty" : ""
        }`}
      >
        <div className="body">
          <div className="types">
            {datas.map((item, index) => (
              <div
                key={index}
                className={`type ${item == current ? "active" : ""}`}
                onClick={() => setCurrent(item)}
              >
                {item.name}
              </div>
            ))}
          </div>
          {current?.list?.length ? (
            <div className="items">
              {current?.list?.map((item) => (
                <Link
                  key={item.packid}
                  replace
                  to={`/welfare/gift?id=${item.packid}`}
                  className="item"
                  onClick={() => (general.gift = item)}
                >
                  <div className="data">
                    <div className="name">{item.packname}</div>
                    <div className="desc">{item.packcontent}</div>
                  </div>
                  <div
                    className={`operate ${item.receiving ? "receiving" : ""} ${
                      item.is_receive ? "received" : ""
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      receive(item);
                    }}
                  />
                </Link>
              ))}
            </div>
          ) : null}
        </div>
        {!current?.list?.length && !loading ? <Empty /> : null}
      </div>
      {receiveData ? (
        <Receive data={receiveData} setData={setReceiveData} update={update} />
      ) : null}
    </>
  );
};
