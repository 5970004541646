import {useLockFn, useMount} from "ahooks";
import Dialog from "../general/Dialog";
import axios from "../../axios";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";

export default ({data, setData, update}) => {
  const receive = useLockFn(async () => {
    if (data.is_receive) {
      return;
    }
    Dialog.loading();
    data.receiving = true;
    update();
    await axios.post('/gift/packReceive', {pack_id: data.packid || data.pack_id})
      .finally(() => {
        Dialog.close();
        data.receiving = false;
        update();
      })
      .then(({data: resData}) => {
        if (resData.code != 0) {
          Dialog.error(resData.msg);
          return;
        }
        data.is_receive = true;
        data.card = resData.data.card;
        update();
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });
  useMount(receive);

  return data?.card ? <div className="gift-receive">
    <div className="body">
      <div className="content">
        <div className="card">礼包码 {data.card}</div>
        <div className="title">使用方法：</div>
        <div className="info">{(data.usage || data.packuse).replace('使用方法：', '')}</div>
      </div>
      <div className="foot">
        <div className="btn">
          <CopyToClipboard text={data.card} onCopy={() => Dialog.info('已复制')}>
            <div onClick={() => setData(null)}>复制礼包码</div>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  </div> : null;
}