import "../css/css.css";
import { useMount } from "ahooks";
import Side from "./general/Side";
import Pay from "./pay/Pay";
import Center from "./general/Center";
import useUrlState from "@ahooksjs/use-url-state";
import useActiveState from "./general/useActiveState";
import axios from "../axios";
import Dialog from "./general/Dialog";
import { useEffect, useState } from "react";
import Game from "./game/Game";
import { useHistory, useLocation } from "react-router-dom";
import general from "../general";

export default () => {
  const history = useHistory();
  const location = useLocation();
  const [init, setInit] = useState();
  const [params] = useUrlState();
  const [app, setApp] = useActiveState("app");
  const setUser = useActiveState("user")[1];
  useMount(() => {
    setApp(params);
    if (params.username) {
      setUser({ username: params.username });
    }
  });

  useMount(() => {
    Dialog.loading();
    axios
      .get("/app/appinfo")
      .finally(() => {
        Dialog.close();
      })
      .then(async ({ data: res }) => {
        if (res.code != 0) {
          Dialog.error(res.msg);
          return;
        }
        window.document.title = res.data.gamename;
        let resData = res.data;
        setApp({
          appinfo: resData.appinfo,
          agreementList: resData.agreementList,
          isChangeSpeedv: resData.isChangeSpeedv,
          loginConfig: resData.loginConfig,
          gamename: resData.gamename,
          appId: resData.appId,
          debug: resData.debug,
          offline: resData.offline,
        });

        let req = new XMLHttpRequest();
        req.open("GET", document.location, false);
        req.send(null);
        if (req.getResponseHeader("token")) {
          params.token = req.getResponseHeader("token");
        }

        await axios
          .get("/game/firstLoad")
          .then(({ data: res }) => {
            if (res.code != 0) {
              Dialog.error(res.msg);
              return;
            }
            setApp({
              appinfo: {
                icon: res.data.list.levitated_sphere,
              },
            });
          })
          .catch((err) => {
            Dialog.error(err.message);
          });

        if (params.username && params.token) {
          Dialog.loading();
          await axios
            .post("/user/appLogin", {
              username: params.username,
              token: params.token,
            })
            .finally(() => {
              Dialog.close();
            })
            .then(({ data: res }) => {
              if (res.code != 0) {
                Dialog.error(res.msg);
                return;
              }
              let resData = res.data;
              setUser({ username: resData.username });
              setApp({
                username: resData.username,
                token: resData.token,
              });
              history.replace(
                resData.autoLogin
                  ? `/?altUsername=${resData.altUsername}`
                  : "/alt"
              );
            })
            .catch((err) => {
              Dialog.error(err.message);
            });
        }

        console.log("APP================-------------", app);

        setInit(true);
      })
      .catch((err) => {
        Dialog.error(err.message);
      });
  });

  general.history = history;
  useEffect(() => {
    if (location.state !== "back") {
      general.urls.push(location);
      if (general.urls.length > 15) {
        general.urls.splice(0, general.urls.length - 10);
      }
    }
  }, [location]);

  const pagePath = () => {
    if (
      location.pathname.startsWith("/user") ||
      location.pathname.startsWith("/welfare") ||
      location.pathname.startsWith("/server") ||
      location.pathname.startsWith("/trade") ||
      location.pathname.startsWith("/service")
    ) {
      return <Side />;
    }

    if (location.pathname.startsWith("/pay")) {
      return <Pay />;
    }

    return <Center />;
  };

  const [screenType, setScreenType] = useState(1);

  useEffect(() => {
    if (init && app.screen == 2) {
      const width = document.documentElement.clientWidth;
      const height = document.documentElement.clientHeight;
      if (width < height) {
        //如果 是竖屏，灵感的宽度就等于屏高
        setScreenType(2);
        Dialog.alert("提示", "请调整手机为横屏模式");
      } //根据手机旋转的角度来判断
      const evt =
        "onorientationchange" in window ? "orientationchange" : "resize";
      //旋转事件
      window.addEventListener(
        evt,
        function () {
          //事件监听
          if (window.orientation === 90 || window.orientation === -90) {
            //旋转到 90 或 -90 度，即竖屏到横屏
            //横屏，灵感的宽度就等于屏高
            setScreenType(1);
            console.log("横屏模式");
          } else {
            //旋转到 180 或 0 度，即横屏到竖屏
            //竖屏，灵感的宽度就等于屏高
            setScreenType(2);
            Dialog.alert("提示", "请调整手机为横屏模式");
          }
        },
        false
      );
    }
  }, [init]);

  return init ? (
    <div className="root">
      <div
        className={`root-container ${
          app.screen == 2 && screenType == 1
            ? "landscape-root-container"
            : "portrait-screen-root-container"
        }`}
      >
        <Game />
        {pagePath()}
      </div>
    </div>
  ) : null;
};
