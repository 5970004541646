import axios from "../../axios";
import {
  PlusCircleOutlined,
  QuestionCircleOutlined,
  RightOutlined,
} from "@ant-design/icons";
import Dialog from "../general/Dialog";
import { useLockFn } from "ahooks";
import { Link, useHistory } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import ActiveContext from "../general/ActiveContext";
import general from "../../general";
import useActiveState from "../general/useActiveState";
import Image from "../general/Image";
import Tips from "../general/Tips";

export default () => {
  const history = useHistory();
  const { active, setCdata } = useContext(ActiveContext);
  const [app] = useActiveState("app");
  const [user, setUser] = useActiveState("user");
  const [alts, setAlts] = useState([]);
  const load = useLockFn(async () => {
    Dialog.loading();
    axios
      .get("/xh/getXhList")
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }

        let resData = res.data.data;

        if (!resData?.realname?.isAdult) {
          Dialog.alert(
            "游客模式说明",
            "根据国家规定，未实名、未成年用户不能进入游戏",
            () => {
              if (resData?.realname?.isAdult === false) {
                history.replace("/login");
              } else {
                history.replace("/realname");
              }
            },
            {
              btn: resData?.realname?.isAdult === false ? "重新登录" : "去认证",
            }
          );
          return;
        }

        setAlts(resData.list);
        setUser({ username: app.username, avator: resData.userlogo });
      })
      .catch((err) => {
        Dialog.error(err.message);
      });
  });
  useEffect(() => {
    if (active) {
      load();
      general.load = load;
    }
  }, [active]);
  useEffect(() => {
    setCdata({
      head: (
        <>
          <div className="user">
            <div className="avatar">
              <Image src={user.avator} />
            </div>
            <div className="name">{user.username}</div>
          </div>
          <div className="operate" onClick={() => history.replace("/login")}>
            切换账号
          </div>
        </>
      ),
    });
  }, [user]);

  return (
    <div className="alt-index">
      <Tips />
      <div className="alt-head">
        <div className="title">
          <div className="text">请选择账号登录</div>
          <QuestionCircleOutlined
            className="icon"
            onClick={() =>
              Dialog.alert(
                "小号说明",
                <>
                  1.每个游戏账号均可登录多款游戏
                  <br />
                  2.同一款游戏，可创建最多10个小号
                  <br />
                  3.每个游戏小号各自独立，等于同一平台账号里面每个游戏可创建多个小号
                  <br />
                </>
              )
            }
          />
        </div>
        <Link replace to="/alt/alt" className="operate">
          <PlusCircleOutlined className="icon" />
          <div className="text">添加小号</div>
        </Link>
      </div>
      <div className="alt-body">
        {alts.map((item) => (
          <div
            key={item.xh_id}
            onClick={() => {
              if (item.status != 1) {
                Dialog.alert("该小号暂不可用");
                return;
              }
              history.replace(
                `/?altUsername=${item.xh_username}&autoLogin=${item.is_default}`
              );
            }}
            className={`alt ${!item.status ? "disable" : ""} ${
              item.is_default ? "active" : ""
            }`}
          >
            <div
              className="select"
              onClick={(e) => {
                e.stopPropagation();
                setAlts((alts) =>
                  alts.map((alt) => ({
                    ...alt,
                    is_default:
                      alt.xh_id === item.xh_id ? (item.is_default ? 0 : 1) : 0,
                  }))
                );
              }}
            >
              <input type="checkbox" defaultChecked={item.is_default} />
              默认
            </div>
            <div className="name">{item.alias}</div>
            <Link
              replace
              to={`/alt/alt?id=${item.xh_id}&alias=${item.alias}`}
              onClick={(e) => {
                e.stopPropagation();
                if (!item.status) {
                  e.preventDefault();
                  Dialog.alert("该小号不可用 可能在交易中");
                }
              }}
              className="operate"
            >
              <div className="text">修改</div>
              <RightOutlined className="icon" />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};
