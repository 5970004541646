import useActiveState from "../general/useActiveState";
import {useState} from "react";
import {useExternal, useInterval, useLockFn, useMount} from "ahooks";
import axios from "../../axios";
import Dialog from "../general/Dialog";
import {useHistory} from "react-router-dom";
import config from "../../config";
import general from "../../general";

export default () => {
  const history = useHistory();
  const [user] = useActiveState('user');

  const [coding, setCoding] = useState(false);
  const [codeTime, setCodeTime] = useState(0);
  useInterval(() => {
    if (codeTime) {
      setCodeTime(codeTime - 1);
    }
  }, 1000);
  const code = ({headers = {}}) => {
    if (coding || codeTime) {
      return;
    }
    setCoding(true);
    axios.post('/sms/getSmsCode', {mobile: user.mobile, type: 'change_password'}, {headers: {...headers}})
      .finally(() => {
        setCoding(false);
      })
      .then(res => {
        if (res.data.code == '-5' || res.data.code == '-11') {
          let captcha = new window.TencentCaptcha(config.captcha.appid, res => {
            if (res.ret === 0) {
              code({headers: {'captcha-randstr': res.randstr, 'captcha-ticket': res.ticket}});
            }
          });
          captcha.show();
          return;
        }
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        setCodeTime(60);
      });
  }
  const [status] = useExternal('https://ssl.captcha.qq.com/TCaptcha.js');

  const [data, setData] = useState({});
  const next = useLockFn(async () => {
    if (!data.code) {
      Dialog.error('请输入验证码');
      return;
    }
    Dialog.loading();
    await axios.post('/user/updatePasswordCheck', {mobile: user.mobile, code: data.code})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        setData({...data, password_token: res.data.data.passwordToken});
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });
  const save = useLockFn(async () => {
    let reqData = {...data};
    console.log(data);
    if (!reqData.password_token) {
      if (!reqData.old_password) {
        Dialog.error('请输入旧密码');
        return;
      }
    }
    if (!reqData.password) {
      Dialog.error('请输入新密码');
      return;
    }
    if (!reqData.again_password) {
      Dialog.error('请确定新密码');
      return;
    }
    Dialog.loading();
    await axios.post(reqData.password_token ? '/user/savePassword' : '/user/changePassword', reqData)
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        Dialog.success('操作成功', general.goBack);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });

  return <div className="user-password">
    {user.mobile && !data.password_token ? <>
      <div className="tips1">我们需要验证您的手机号码</div>
      <div className="tips2">手机号：{user.mobile?.replace(/^(\d{3})\d{4}(\d+)/, '$1****$2')}</div>
      <div className="input">
        <input type="text" placeholder="请输入验证码" onChange={e => setData({...data, code: e.target.value})}/>
        <div className={'codebtn' + (coding || codeTime ? ' disable' : '')} onClick={code}>
          {coding ? '获取中' : (codeTime ? (codeTime + '秒后重试') : '获取验证码')}
        </div>
      </div>
      <div className="operate" onClick={next}>下一步</div>
    </> : <>
      <div className="title">您正在修改<span>{user.username}</span>的密码</div>
      {!data.password_token ? <div className="input">
        <input type="password" placeholder="请输入原密码" onChange={e => setData({...data, old_password: e.target.value})}/>
      </div> : null}
      <div className="input">
        <input type="password" placeholder="请输入新密码" onChange={e => setData({...data, password: e.target.value})}/>
      </div>
      <div className="input">
        <input type="password" placeholder="请确定新密码" onChange={e => setData({...data, again_password: e.target.value})}/>
      </div>
      <div className="operate" onClick={save}>确认</div>
    </>}
  </div>;
}
